import { Inject } from '@angular/core';
import { Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { AuthService } from '@auth0/auth0-angular';
import {ConfigurationService} from "@core/configuration.service";
const { authorizationParams } = ConfigurationService.environment.webAuthConfig;

@Injectable({ providedIn: 'root' })
export class AuthWebService {
	constructor(
		private readonly auth: AuthService,
		@Inject(DOCUMENT) private readonly doc: Document
	) {}

	public login() {
		return this.auth.loginWithRedirect({ display: 'sup' } as any);
	}

	public logout() {
		this.auth.logout({ logoutParams: { returnTo: document.location.origin }});
	}
}
